import supplierContactServices from '../../../../services/provider/supplier-contacts'
import emailService from '../../../../services/provider/email'
import { replace } from 'lodash'
export default {
    components: {},

    data() {
        return {
            item: [],
            admin: [],
            Listitems: [],
            filesUpload: [],
            formType: 'INSERT',
            status: '',
            isCheckAll: false,
            isCheckboxEnable: false,
            bodyContent: null,
        }
    },
    created() {
        this.getEmail('SUPPLIER_CONTACT_' + this.$store.state.organization.id)
    },
    mounted() { },
    computed: {
        GetUserRole() {
            return this.$store.state.roleName
        },
        form() {
            if (this.formType == 'INSERT') {
                return {
                    title: 'Would you like to send recall response to supplier?',
                    showAdminCreation: true,
                    showStatusSwitcher: false,
                }
            }
            if (this.formType == 'UPDATE') {
                return {
                    title: 'Update Supplier',
                    showAdminCreation: false,
                    showStatusSwitcher: true,
                }
            }
        },
        htmlRender() {
            return `<div> ${this.bodyContent} </div>`
        }
    },
    methods: {
        sendEmail() {
            var ListAction = this.Listitems
            var Docitems = this.filesUpload
            let Lenth = 0
            Docitems.forEach((x, k) => {
                Lenth = Lenth + x.file.size
            })
            if (Lenth > 10 * 1024 * 1024) {
                this._showToast(
                    'The maximum size of the documents to be uploaded should be 10MB.',
                    { variant: 'danger', title: 'Error' }
                )
            } else {
                let formData = new FormData()
                let index = 0
                Docitems.forEach((x, k) => {
                    if (x.file != undefined) {
                        formData.append('documents', x.file)
                        index++
                    }
                })

                let i = 0
                ListAction.forEach((x) => {
                    if (x.isSelected == true) {
                        formData.append('emailList[' + i + '].email', x.email)
                        formData.append(
                            'emailList[' + i + '].supplierName',
                            x.supplierName
                        )
                        formData.append('emailList[' + i + '].fullName', x.fullName)
                        formData.append(
                            'emailList[' + i + '].supplierContactId',
                            x.supplierContactId
                        )
                        ++i
                    }
                })
                if (i == 0) {
                    this._showToast('No contacts have been selected', {
                        variant: 'danger',
                        title: 'Error',
                    }).goAway(200)
                }
                supplierContactServices
                    .SendSupplierContactEmail(formData)
                    .then((resp) => {
                        if (!resp.error) {
                            this._showToast('Email sent successfully!')
                            var supplierContactIdsList = []
                            this.Listitems.forEach((x) => {
                                var supplierContactIds = []
                                supplierContactIds.emailTime = resp.data.d
                                supplierContactIds.supplierContactId = x.supplierContactId
                                supplierContactIdsList.push(supplierContactIds)
                            })
                            this.Listitems = []
                            this.filesUpload = []
                            this.$refs.modal.hide()
                            this.$emit('onCompleted', supplierContactIdsList)
                        } else {
                            this._showToast('No contacts have been selected', {
                                variant: 'danger',
                                title: 'Error',
                            }).goAway(1500)
                        }
                    })
            }
        },

        forceRerenderInsert(val) {
            this.Listitems = []
            this.filesUpload = []
            this.isCheckAll = false
            val.forEach((x) => {
                this.Listitems.push({
                    isSelected: true,
                    email: x.email,
                    supplierName: x.supplierName,
                    fullName: x.full_name,
                    supplierContactId: x.supplier_contactid,
                })
            })
            if (this.$store.state.user != '') {
                var userData = this.$store.state.user
                this.admin.name = userData.fname + ' ' + userData.lname
                this.admin.email = userData.email
                this.admin.title = ''
                this.admin.companyname = userData.org_name
                this.admin.phoneno = userData.phone ?? ''
            } else {
                this.admin.name = ''
                this.admin.email = ''
                this.admin.title = ''
                this.admin.companyname = ''
                this.admin.phoneno = ''
            }
            this.formType = 'INSERT'
            this.$refs.modal.show()
            this.isCheckAll = false
        },
        cancel() {
            this.$refs.modal.hide()
        },
        handleFilesUpload() {
            let uploadedFiles = this.$refs.files.files
            for (let i = 0; i < uploadedFiles.length; i++) {
                if (uploadedFiles[i].size == 0) {
                    this._showToast('Documented failed to upload. Please try again.', { variant: 'danger' })
                }
                else {
                    this.filesUpload.push({
                        file: uploadedFiles[i],
                        note: '',
                        is_thumbnail: false,
                    })
                }
            }
            this.$refs.files.value = ''
        },
        removeDocument(index) {
            this.filesUpload.splice(index, 1)
            this.thumbnailIndex = this.filesUpload.findIndex(
                (x) => x.is_thumbnail === true
            )
        },
        checkboxChange(item, event) {
            if (event.target.checked) {
                item.isSelected = false
            } else {
                item.isSelected = true
            }
        },
        getEmail(type) {
            emailService.getEmail(type).then(resp => {
                if (!resp.error) {
                    this.bodyContent = resp.data.d.content
                    this.bodyContent = this.bodyContent.replace(this.bodyContent.substr(this.bodyContent.indexOf('<p>Sincerely,')), this.bodyContent.substr(this.bodyContent.indexOf('<p>Sincerely,')).replaceAll('</p>\n<p>', '<br />'))
                    this.setVariableValues()
                }
            })
        },
        setVariableValues() {
            var user = this.$store.state.user
            var title = (s) => s === null ? '##PROVIDER_TITLE##' : s
            var sites = (s) => s === null || s === undefined ? '##PROVIDER_SITES##' : s
            var phone = (s) => s === '0000000000' || s === undefined ? '##PROVIDER_PHONE##' : s

            this.bodyContent = this.bodyContent
                .replaceAll('</p>', '</p><br />')
                .replaceAll('${SalesRepName}', '')
                .replaceAll('${SupplierName}', '')
                .replaceAll('${ProviderName}', user.org_name)
                .replaceAll('${ProviderUserName}', user.full_name)
                .replaceAll('${ProviderUserRole}', user.role)
                .replaceAll('${ProviderUserTitle}', title(user.title))
                .replaceAll('${ProviderUserSites}', sites(user.userSite))
                .replaceAll('${ProviderUserEmailAddress}', user.email)
                .replaceAll('${ProviderUserPhoneNumber}', phone(user.phone))

            if (this.bodyContent.includes('##PROVIDER_TITLE##')) {
                this.bodyContent = this.bodyContent.replaceAll('##PROVIDER_TITLE##<br />', '')
                this.bodyContent = this.bodyContent.replaceAll('<p>##PROVIDER_TITLE##</p><br />', '')
                this.bodyContent = this.bodyContent.replaceAll('##PROVIDER_TITLE##', '')
            }
            if (this.bodyContent.includes('##PROVIDER_SITES##')) {
                this.bodyContent = this.bodyContent.replaceAll('##PROVIDER_SITES##<br />', '')
                this.bodyContent = this.bodyContent.replaceAll('<p>##PROVIDER_SITES##</p><br />', '')
                this.bodyContent = this.bodyContent.replaceAll('##PROVIDER_SITES##', '')
            }
            if (this.bodyContent.includes('##PROVIDER_PHONE##')) {
                this.bodyContent = this.bodyContent.replaceAll('##PROVIDER_PHONE##<br />', '')
                this.bodyContent = this.bodyContent.replaceAll('<p>##PROVIDER_PHONE##</p><br />', '')
                this.bodyContent = this.bodyContent.replaceAll('##PROVIDER_PHONE##', '')
            }
        },
    },
}
