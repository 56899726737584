import VueDropzone from 'vue2-dropzone'
import excelParser from '../../../../services/excel-parser'
import supplierContactServices from '../../../../services/provider/supplier-contacts'
import _ from 'lodash'
export default {
    components: {
        VueDropzone
    },
    data() {
        return {
            item: {},
            listHeader: [],
            isFileSelect: false,

            labelName: {
                supplierName: 'Supplier Name',
                first_name: 'First Name',
                last_name: 'Last Name',
                email: 'Email',
                title: 'Title',
                phone_number: 'Phone Number',
            },

            dropzoneOptions: {
                url: '#',
                acceptedFiles: '.xlsx,.xls',
                maxFiles: 1,
                maxFilesize: 20,
                previewsContainer: false,
                autoProcessQueue: false
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.$refs.modal.show()
        },

        addSuccess(file) {
            this.isFileSelect = false
            this.item = {
                supplierName: null,
                first_name: null,
                last_name: null,
                email: null,
                title: null,
                phone_number: null,
                file: null
            }

            if (file.name && _.isArray(file.name.split('.'))) {
                var ext = file.name.split('.')
                if (['xlsx'].includes(ext[ext.length - 1])) {
                    this.item.file = file
                    try {
                        let formData = new FormData()
                        formData.append('file', this.item.file)
                        excelParser.getExcelHeaders(formData).then(res => {
                            if (!res.error) {
                                this.listHeader = res.data.d.map(x => { return { value: x, text: x } })
                                this.isFileSelect = true
                                this._showToast('File successfully uploaded', { variant: 'success' })
                            }
                        })
                    } catch (e) {
                        console.clear()
                    }
                } else {
                    this._showToast('Only accept  xlsx file format', { variant: 'danger' })
                }
            }
        },

        async save() {
            var isValid = await this.$refs['observer'].validate()
            if (isValid) {
                let formData = new FormData()
                formData.append('SupplierName', this.item.supplierName)
                formData.append('FirstName', this.item.first_name)
                formData.append('LastName', this.item.last_name)
                formData.append('Email', this.item.email)
                formData.append('Title', this.item.title ? this.item.title : '')
                formData.append('PhoneNumber', this.item.phone_number ? this.item.phone_number : '')
                formData.append('File', this.item.file)
                supplierContactServices.uploadFile(formData).then(res => {
                    if (!res.error) {
                        this._showNotify('Successfully uploaded', res.data.d.created + ' supplier contacts created, ' + res.data.d.updated + ' supplier contacts updated', 'success')
                        this.$emit('onCompleted', 'UPDATE')
                        this.$refs.modal.hide()
                    }
                })
            }
        },
    }
}