import supplierContactServices from '../../../services/provider/supplier-contacts'
import SupplierContactModel from './SupplierContactModel'
import { SITE_STATUS } from '../../../misc/commons'
import SupplierContactImport from './SupplierContactImport'
import ResponseModal from './ResponseModal'
import ErrorModal from '../../share/ErrorModal'
import { SUPLLIER_EMAIL_STATUS, EMAIL_STATUS } from '../../../misc/commons'
export default {
    components: {
        SupplierContactModel,
        SupplierContactImport,
        ResponseModal,
        ErrorModal
    },
    data() {
        return {
            total: 0,
            items: [],
            supplierContactedIds: [],
            organization_id: null,
            e: null,
            searchQuery: {},
            emailStatus: EMAIL_STATUS,
            supplier_status: SITE_STATUS,
            supplierEmailStatus: SUPLLIER_EMAIL_STATUS,
            supplierContactModelKey: 0,
            suppliercontactImportKey: 0,
            respModalKey: 0,
            isCheckAll: false,
            isEnable: false,
            isCheckboxEnable: false,
            Listitems: [],
            suppliercontactIds: [],
            autonotifychecked: false,
            errorModalKey: 0,
            header: [
                {
                    label: '',
                    name: 'select',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'pl-2'
                },
                {
                    label: 'Supplier',
                    name: 'supplierName',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Name',
                    name: 'full_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Title',
                    name: 'title',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Email',
                    name: 'email',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Phone',
                    name: 'phone_number',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Last Contact Result',
                    name: 'last_change_at',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Timestamp',
                    name: 'emailsentdatetime',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right',
                }
            ],
        }
    },
    mounted() {
    },
    created()
    {
        this.getAutoNotifySetting()
    },

    computed: {
        currentUserId() {
            return this.$store.state.user.id ? this.$store.state.user.id : -1
        }
    },
    methods: {
        onCompleted(data) {
            if(data != 'INSERT' && data != 'DELETE' && data != 'UPDATE')
            {
                this.supplierContactedIds = data
            }
            this.Listitems=[]
            this.fetchData()
        },
        checkboxChange(item, event) {
            if (event.target.checked) {
                this.Listitems.push(item)
            }
            else {

                this.Listitems.forEach(item1 => {
                    if (item1.supplier_contactid == item.supplier_contactid) {
                        this.Listitems.splice(this.Listitems.indexOf(item1), 1)
                    }
                })

            }
            if (this.items.length == this.Listitems.length) {

                this.isCheckAll = true
            }
            else {
                this.isCheckAll = false
            }
            if (this.Listitems.length > 0 && !this.isEnable) {
                this.$refs.supplierContactModel.$el.parentElement.children[0].children[0].children[1].children.disVal.click()
                this.isEnable = true
            }
            if (this.Listitems.length == 0) {
                this.$refs.supplierContactModel.$el.parentElement.children[0].children[0].children[1].children.disVal.click()
                this.isEnable = false
            }
        },
        checkboxAllChange() {
            this.suppliercontactIds = []
            this.verifyLocation = []
            this.Listitems = []
            if (this.isCheckAll)
                this.items.forEach(item => {
                    this.Listitems.push(item)
                    this.suppliercontactIds.push(item.supplier_contactid.toString())
                })
            if (this.Listitems.length > 0 && !this.isEnable) {
                this.$refs.supplierContactModel.$el.parentElement.children[0].children[0].children[1].children.disVal.click()
                this.isEnable = true
            }
            if (this.Listitems.length == 0) {
                this.$refs.supplierContactModel.$el.parentElement.children[0].children[0].children[1].children.disVal.click()
                this.isEnable = false
            }
        },
        importSupplierContacts() {
            this.suppliercontactImportKey += 1
            this.$nextTick(()=>{
                this.$refs.suppliercontactImport.forceRerenderInsert()
            })
        },
        getautonotifyvalue(value)
        {
            this.autonotifychecked=value
            supplierContactServices.UpdateAutoNotifySetting(this.autonotifychecked).then(resp => {

            })
        },
        addContactEmailClick() {
            this.$nextTick(() => {
                this.$refs.respModal.forceRerenderInsert(this.Listitems)
            })
        },
        addMoreClick() {
            this.supplierContactModelKey += 1
            this.$nextTick(() => {
                this.$refs.supplierContactModel.forceRerenderInsert(this.organization_id)
            })
        },
        updateSupplierContacts(payload, e = null) {
            this.supplierContactModelKey += 1
            this.$nextTick(() => {
                this.$refs.supplierContactModel.forceRerenderUpdate(payload)
            })
            if (e) {
                e.stopPropagation()
            }
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            //Clear all checkbox set to false
            this.isCheckAll = false
            this.checkboxAllChange()
            supplierContactServices.findSupplierContacts({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                organizationId: this.organization_id
            }).then(resp => {
                if (!resp.error) {
                    if(resp.data.d.c.length > 0)
                    {
                        if(this.supplierContactedIds != null && this.supplierContactedIds.length > 0)
                        {
                            var supList = []
                            this.supplierContactedIds.forEach((x) => {
                                supList.push(x.supplierContactId)
                            })
                            resp.data.d.c.forEach((x) => {
                                if(supList.includes(x.supplier_contactid))
                                {
                                    this.supplierContactedIds.forEach((y) => {
                                        if(x.supplier_contactid == y.supplierContactId)
                                        {
                                            x.emailsentdatetime = y.emailTime
                                        }
                                    })
                                }
                            })
                            this.supplierContactedIds = []
                        }
                    }
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                    if(this.items.length==0)
                    {
                        this.isCheckboxEnable=false
                    }
                    else{
                        this.isCheckboxEnable=true
                    }
                    this.getAutoNotifySetting()
                }
            })
        },
        getAutoNotifySetting()
        {
            supplierContactServices.getAutoNotifySetting().then(resp => {
                if (!resp.error) {
                    this.autonotifychecked=resp.data.d.isautonotify
                }
            })
        },
        deleteSupplierContacts(item, e) {
            this._showConfirm('Are you sure do you want to delete the contact, this cannot be undone?').then(confirm => {
                if (confirm) {
                    supplierContactServices.deleteSupplierContacts(item.supplier_contactid).then(resp => {
                        if (!resp.error) {
                            this.onCompleted('DELETE')
                        }
                    })
                }
            })
            e.stopPropagation()
        },
        ViewErrorModal(item) {
            this.errorModalKey += 1
            this.$nextTick(() => {
                this.$refs.errorModal.forceRerenderView(
                    item
                )
            })
        },
    }
}